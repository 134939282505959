define("heimdall-admin/mirage/factories/user", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    username: function username() {
      return _emberCliMirage.faker.internet.email();
    },


    role: "user"

  });
});