define("heimdall-admin/controllers/users/index", ["exports", "ember"], function (exports, _ember) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Controller.extend({

    userColumns: [{
      propertyName: "username",
      title: "Username"
    }, {
      propertyName: "role",
      title: "Role"
    }, {
      template: "users/columns/actions",
      title: "Actions"
    }]

  });
});